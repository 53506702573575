<!--门卡设置记录-->
<!-- 0授权卡 1总控 2楼 3层 4设置组卡 5组卡 6设置房卡 7房卡 8退房卡-->

<template>
  <table-view
    class="aalock-card-record"
    :total="total"
    v-loading="loading"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        label-width="55px"
        :queryInfo.sync="queryInfo"
        @on-search="renderTable(1)">
        <el-form-item label="门卡类型" prop="build" label-width="80px">
          <el-select
            size="small"
            v-model="queryInfo.cardType"
            @change="renderTable(1)"
            clearable
            filterable>
            <el-option
              v-for="{value,label} in cardTypeList"
              :label="label"
              :value="value"
              :key="value" />
          </el-select>
        </el-form-item>
        <el-form-item label="宿舍楼" prop="build" label-width="55px">
          <el-select
            size="small"
            v-model="queryInfo.build"
            @change="changeBuild"
            clearable
            filterable>
            <el-option
              v-for="{dormitoryName,lockNo,id} in buildList"
              :label="dormitoryName"
              :value="lockNo"
              :key="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="楼层">
          <el-select
            size="small"
            v-model="queryInfo.floor"
            clearable
            filterable
            @change="getDormRoomList">
            <el-option
              v-for="floor in floorList"
              :label="floor+'层'"
              :value="floor"
              :key="floor" />
          </el-select>
        </el-form-item>
        <el-form-item label="宿舍">
          <el-select size="small" v-model="queryInfo.house" clearable filterable @change="renderTable(1)">
            <el-option
              v-for="{roomName,lockNo} in roomList"
              :label=" roomName"
              :value="lockNo"
              :key="lockNo" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="createTime" label="设卡时间" width="180" />
      <el-table-column prop="stuName" label="姓名/宿舍楼/卡类型" width="200">
        <template v-slot="{row}">
          <span v-if="row.stuName">{{ row.stuName }}</span>
          <span v-else-if="row.username">{{ row.username }}</span>
          <span v-else class="font-grey">--{{ row.cardType === 2 ? row.dormitoryName : cardTypeList[row.cardType].label
            }}--</span>
        </template>
      </el-table-column>
      <el-table-column prop="serialNumber" label="流水号" width="100" align="center" />
      <el-table-column label="卡状态" width="100" align="center">
        <template v-slot="{row}">
          <el-tag :type="row.enableFlag?'success':'info'">{{ row.enableFlag ? '启用' : '禁用' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="aalockId" label="AALockID" width="190" align="center" />
      <el-table-column label="地址" width="300">
        <template v-slot="{row}">
          <span v-if="row.dormitoryName">{{row.dormitoryName}}</span>
          <span v-if="row.floor">第{{ row.floor }}层</span>
          <span v-if="row.roomName">{{ row.roomName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" min-width="170" label="创建时间" align="center" />
      <el-table-column label="操作" width="200" fixed="right">
        <template #header>
          <tool-tips-group tips-content=" <b>**功能操作前提**：</b><br>
              启用或禁用功能时，需确保NFC读卡器处于工作状态。<br>
               <b> - **操作流程**：</b><br>
                  1. 将空白卡片放置于NFC读卡器上。<br>
                  2. 成功执行操作后，卡片将被写入特定数据。<br>
                  3. 最后，需使用已写入数据的卡片在宿舍门锁上进行刷卡操作。<br>
                <b>- **效果达成**：</b><br>
                完成上述步骤后，功能启用或禁用状态将在门锁中生效。">操作
          </tool-tips-group>
        </template>
        <template v-slot="{row}">
          <!--  按钮显示规则：
          ---以下角色条件二选一：
          1. 管理员、开发人员；
          2.楼栋管理员（row.userId===userInfo.id）
          ---以下门锁设置条件二选一：
          1.存在学生名；(stuName)
          2.存在教职工名（username）
          -->
          <template v-if="(isAdmin||$store.state.login.userInfo.id===row.userId)&&(row.stuName||row.username)">
            <template v-if="row.enableFlag">
              <el-button type="danger" @click="handleSwitch(row,0)">
                禁用
              </el-button>
            </template>
            <el-button v-else type="text" @click="handleSwitch(row,1)">
              启用
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>

  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { bindDormCardByUserIdApi, pageLockCardByCodeApi } from '@/api/dorm/lock-api'
import nfcCard from '@/vue/mixins/nfc-card.js'
import store from '@/store/index'

export default {
  name: 'aaLockCardRecord',
  components: {},
  mixins: [tableView, nfcCard],
  data() {
    return {
      cardTypeList: [
        {
          value: 0,
          label: '授权卡'
        },
        {
          value: 1,
          label: '总控'
        },
        {
          value: 2,
          label: '楼'
        },
        {
          value: 3,
          label: '层'
        },
        {
          value: 4,
          label: '设置组卡'
        },
        {
          value: 5,
          label: '组卡'
        },
        {
          value: 6,
          label: '设置房卡'
        },
        {
          value: 7,
          label: '房卡'
        },
        {
          value: 8,
          label: '退房卡'
        }
      ],
      queryInfo: {
        build: null, // 宿舍楼号 获取 list的lockNo
        floor: null,
        cardType: null, // 授权卡类型
        house: null // 宿舍号 获取 list的lockNo
      },
      isAdmin: store.state.login.isAdmin,
      buildList: [], // 楼栋
      floorList: 0, // 楼层
      roomList: [], // 宿舍
      loading: false
    }
  },
  async mounted() {
    await Promise.all([
      this.getDormBuild(),
      this.renderTable(1)
    ])
  },
  watch: {
    'queryInfo.build': function(newVal) {
      if (!newVal) {
        this.floorList = 0
        this.roomList = []
      }
    },
    'queryInfo.floor': function(newVal) {
      if (!newVal) {
        this.roomList = []
      }
    }
  },
  methods: {
    async renderTable(pageNum) {
      await this.getTableData(pageLockCardByCodeApi, pageNum)
    },
    // 获取楼栋
    async getDormBuild() {
      // 1 获取宿舍楼list
      try {
        const res = await this.$http.getDormBuildQuery({})
        this.buildList = res.data
        // 2.如果选了宿舍楼,就根据宿舍楼加载楼层list
        await this.createFloor(this.queryInfo.dormitoryId)
        // 3.由于数据量太大,所以不建议初始化时就加载整个学校的宿舍数据,除非有必要
      } catch (e) {
        console.error(e)
      }
    },
    // 生成楼层
    createFloor(lockNo) {
      this.floorList = []
      if (lockNo) {
        this.floorList = (this.buildList.find((item) => item.lockNo === lockNo).floorAmount) || 0
      }
    },
    // 清空数组
    clearList(arr) {
      while (arr.length) arr.pop()
    },
    // 获取宿舍列表
    async getDormRoomList(val) {
      this.clearList(this.roomList)
      this.queryInfo.roomId = null
      if (!val) {
        await this.renderTable(1)
        return
      }
      try {
        const dormitoryId = this.buildList.find(item => item.lockNo === this.queryInfo.build).id
        const res = await this.$http.getDormRoomQuery({
          dormitoryId,
          floorNum: val
        })
        this.roomList = res.data
        await this.renderTable(1)
      } catch (e) {
        console.error(e)
      }
    },
    // 选择楼栋
    async changeBuild(id) {
      this.clearList(this.roomList)
      this.floorList = 0
      this.queryInfo.floor = null
      this.queryInfo.roomId = null
      if (id) {
        this.createFloor(id)
        await this.renderTable(1)
      } else {
        this.queryInfo.dormitoryId = null
      }
    },
    handleSwitch(row, flag) {
      this.$confirm(`<p classs="confirm-message">是否确定${flag ? '<b class="bg-primary">启用</b>' : '<b class="primary">禁用</b>'}此门卡？</p>`, {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          this.loading = true
          // 把数据存入enableRow ，写入失败后把状态返回为原来的
          this.enableFlag = true
          this.enableRow = JSON.parse(JSON.stringify(row))
          this.enableRow.enableFlag = flag ? 0 : 1
          // 开始修改状态
          const _key = await this.handleEnable(row, flag)
          const d = '00000000000000000000000000000000' + _key
          // 把返回的信息写入nfc卡
          this.readNfc(d)
          this.$message({
            message: `${flag ? '启用' : '禁用'}成功，数据正在写入NFC卡，请稍等。`,
            type: flag ? 'success' : 'warning'
          })
        } catch (e) {
          await this.handleEnable(row, row.enableFlag)
          console.error(e)
        } finally {
          await this.renderTable()
          this.loading = false
        }
      }).catch(() => {
        this.$message.info('已取消')
      })
    },
    // 发卡（绑定学生）
    async bindStudent(dromBed) {
      if (!this.title.lockInfo) {
        this.$message.info('未设置门锁！')
      } else if (!this.title.lockInfo.lockType) {
        this.$message.info('旧锁不提供绑定nfc的功能！')
      } else {
        try {
          this.loading = true
          const res = await bindDormCardByUserIdApi({
            aalockId: this.title.lockInfo.id,
            userId: dromBed.userId
          })
          const d = '00000000000000000000000000000000' + res.data
          this.readNfc(d)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
