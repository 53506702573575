var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"aalock-card-record",attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"label-width":"55px","queryInfo":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"门卡类型","prop":"build","label-width":"80px"}},[_c('el-select',{attrs:{"size":"small","clearable":"","filterable":""},on:{"change":function($event){return _vm.renderTable(1)}},model:{value:(_vm.queryInfo.cardType),callback:function ($$v) {_vm.$set(_vm.queryInfo, "cardType", $$v)},expression:"queryInfo.cardType"}},_vm._l((_vm.cardTypeList),function(ref){
var value = ref.value;
var label = ref.label;
return _c('el-option',{key:value,attrs:{"label":label,"value":value}})}),1)],1),_c('el-form-item',{attrs:{"label":"宿舍楼","prop":"build","label-width":"55px"}},[_c('el-select',{attrs:{"size":"small","clearable":"","filterable":""},on:{"change":_vm.changeBuild},model:{value:(_vm.queryInfo.build),callback:function ($$v) {_vm.$set(_vm.queryInfo, "build", $$v)},expression:"queryInfo.build"}},_vm._l((_vm.buildList),function(ref){
var dormitoryName = ref.dormitoryName;
var lockNo = ref.lockNo;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":dormitoryName,"value":lockNo}})}),1)],1),_c('el-form-item',{attrs:{"label":"楼层"}},[_c('el-select',{attrs:{"size":"small","clearable":"","filterable":""},on:{"change":_vm.getDormRoomList},model:{value:(_vm.queryInfo.floor),callback:function ($$v) {_vm.$set(_vm.queryInfo, "floor", $$v)},expression:"queryInfo.floor"}},_vm._l((_vm.floorList),function(floor){return _c('el-option',{key:floor,attrs:{"label":floor+'层',"value":floor}})}),1)],1),_c('el-form-item',{attrs:{"label":"宿舍"}},[_c('el-select',{attrs:{"size":"small","clearable":"","filterable":""},on:{"change":function($event){return _vm.renderTable(1)}},model:{value:(_vm.queryInfo.house),callback:function ($$v) {_vm.$set(_vm.queryInfo, "house", $$v)},expression:"queryInfo.house"}},_vm._l((_vm.roomList),function(ref){
var roomName = ref.roomName;
var lockNo = ref.lockNo;
return _c('el-option',{key:lockNo,attrs:{"label":roomName,"value":lockNo}})}),1)],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"createTime","label":"设卡时间","width":"180"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名/宿舍楼/卡类型","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.stuName)?_c('span',[_vm._v(_vm._s(row.stuName))]):(row.username)?_c('span',[_vm._v(_vm._s(row.username))]):_c('span',{staticClass:"font-grey"},[_vm._v("--"+_vm._s(row.cardType === 2 ? row.dormitoryName : _vm.cardTypeList[row.cardType].label)+"--")])]}}])}),_c('el-table-column',{attrs:{"prop":"serialNumber","label":"流水号","width":"100","align":"center"}}),_c('el-table-column',{attrs:{"label":"卡状态","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.enableFlag?'success':'info'}},[_vm._v(_vm._s(row.enableFlag ? '启用' : '禁用'))])]}}])}),_c('el-table-column',{attrs:{"prop":"aalockId","label":"AALockID","width":"190","align":"center"}}),_c('el-table-column',{attrs:{"label":"地址","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName)?_c('span',[_vm._v(_vm._s(row.dormitoryName))]):_vm._e(),(row.floor)?_c('span',[_vm._v("第"+_vm._s(row.floor)+"层")]):_vm._e(),(row.roomName)?_c('span',[_vm._v(_vm._s(row.roomName))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","min-width":"170","label":"创建时间","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","width":"200","fixed":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tool-tips-group',{attrs:{"tips-content":" <b>**功能操作前提**：</b><br>\n            启用或禁用功能时，需确保NFC读卡器处于工作状态。<br>\n             <b> - **操作流程**：</b><br>\n                1. 将空白卡片放置于NFC读卡器上。<br>\n                2. 成功执行操作后，卡片将被写入特定数据。<br>\n                3. 最后，需使用已写入数据的卡片在宿舍门锁上进行刷卡操作。<br>\n              <b>- **效果达成**：</b><br>\n              完成上述步骤后，功能启用或禁用状态将在门锁中生效。"}},[_vm._v("操作 ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [((_vm.isAdmin||_vm.$store.state.login.userInfo.id===row.userId)&&(row.stuName||row.username))?[(row.enableFlag)?[_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.handleSwitch(row,0)}}},[_vm._v(" 禁用 ")])]:_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleSwitch(row,1)}}},[_vm._v(" 启用 ")])]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }